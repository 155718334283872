import { theme as customTheme } from "../../utils/CustomTheme";
import { Theme } from "@mui/material/styles";

export const styles = {
  backdrop: {
    color: customTheme.palette.common.white,
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  },
  container: (theme: Theme) => ({
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(3),
  }),
};
