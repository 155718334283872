import axios from "axios";

export const getClient = (
  baseURL: string = `${process.env.REACT_APP_API_GATEWAY}`
) => {
  return axios.create({
    baseURL,
    headers: {
      "Content-type": "application/json",
    },
  });
};

export default getClient();
