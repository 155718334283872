import { useCallback, useState, useEffect } from "react";
import { Collapse, Alert, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface FormAlertErrorProps {
  isError: boolean;
  message: string;
}

const FormAlertError = ({ isError, message }: FormAlertErrorProps) => {
  const [openAlert, setOpenAlert] = useState<boolean>(true);
  const closeAlert = (): void => setOpenAlert(false);

  const renderErrorAlert = useCallback(
    (msg: string) => (
      <Collapse in={openAlert}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={closeAlert}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {msg}
        </Alert>
      </Collapse>
    ),
    [openAlert]
  );

  useEffect(() => {
    setOpenAlert(isError);
  }, [isError]);

  return <Box>{isError && renderErrorAlert(message)}</Box>;
};

export default FormAlertError;
