import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import { theme } from "./utils/CustomTheme";
import Home from "./views/Home/Home";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container className="App">
        <Router>
          <Grid container>
            <Grid item xs={12}>
              <Routes>
                <Route path="/" element={<Home />} />
              </Routes>
            </Grid>
          </Grid>
        </Router>
      </Container>
    </ThemeProvider>
  );
}

export default App;
