import http from "../utils/HttpCommon";

export interface MediaRecordType {
  stocknumber: string;
  language: string;
  version: string;
  bible_id: string;
  media_id: string;
  description: string;
  codec: string | null;
  bitrate: string | null;
}

export interface ProductType {
  media_records: MediaRecordType[];
}

export interface ProductTypeRequest {
  data: ProductType;
}

export interface ProductRequestParameters {
  productCode: string;
}

const get = async (params: ProductRequestParameters): Promise<ProductType> => {
  const response = await http.get<ProductTypeRequest>(`/product`, {
    params,
  });

  const { data: dataFetched } = response;
  const { data } = dataFetched;

  return data;
};

const ProductService = {
  get,
};

export default ProductService;
