import { getClient } from "../utils/HttpCommon";

export interface PackageType {
  url: string;
}

export interface PackageTypeRequest {
  data: string;
}

export interface PackageRequestParameters {
  mediaId: string | null;
  bibleId: string | null;
  format?: string | null;
  id3v2?: string | null;
}

const http = getClient(process.env.REACT_APP_PACKAGE_LAMBDA_URL);

const get = async (params: PackageRequestParameters): Promise<PackageType> => {
  const response = await http.get<PackageTypeRequest>(`/package`, {
    params,
  });

  const { data: dataFetched } = response;
  const { data } = dataFetched;

  return {
    url: data,
  };
};

const PackageService = {
  get,
};

export default PackageService;
