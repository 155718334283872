import { useQuery } from "@tanstack/react-query";
import PackageService, {
  PackageRequestParameters,
  PackageType,
} from "../services/PackageService";

export interface PackageHookParameters extends PackageRequestParameters {
  mediaId: string;
  productCode: string;
}

export const useGetPackage = (params: PackageHookParameters) => {
  const { isFetching, isError, data, error, refetch } = useQuery<
    PackageType,
    Error
  >(
    ["query-get-package", params],
    () => {
      return PackageService.get(params);
    },
    { enabled: false, retry: false, retryOnMount: false }
  );

  return {
    isFetching,
    isError,
    data,
    error,
    refetch,
  };
};
