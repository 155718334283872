import { useQuery } from "@tanstack/react-query";
import ProductService, { ProductType } from "../services/ProductService";

interface UseProductQueryProps {
  productCode: string;
}

export const useGetProduct = ({ productCode }: UseProductQueryProps) => {
  const { isFetching, isError, data, error, refetch } = useQuery<
    ProductType,
    Error
  >(
    ["query-get-product", productCode],
    () => ProductService.get({ productCode }),
    {
      enabled: false,
      retry: false,
      retryOnMount: false,
    }
  );

  return {
    isFetching,
    isError,
    data,
    error,
    refetch,
  };
};
