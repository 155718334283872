import { useEffect, useState } from "react";
import { downloadFile } from "../utils/Helpers";
import { useGetPackage, PackageHookParameters } from "./useGetPackage";

export const useGetMediaRecord = () => {
  const [mediaRecordToDownload, setMediaRecordToDownload] =
    useState<PackageHookParameters>({
      mediaId: "",
      productCode: "",
      bibleId: "",
    });

  const {
    isFetching,
    isError,
    data: productPackage,
    refetch: fetchURLToDownloadPackage,
  } = useGetPackage(mediaRecordToDownload);

  useEffect(() => {
    if (productPackage) {
      const { url } = productPackage;
      downloadFile(url);
    }
  }, [productPackage]);

  useEffect(() => {
    const { mediaId } = mediaRecordToDownload;

    if (mediaId) {
      fetchURLToDownloadPackage();
    }
  }, [mediaRecordToDownload, fetchURLToDownloadPackage]);

  return {
    isFetching,
    isError,
    setMediaRecordToDownload,
  };
};
