import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Grid,
  Box,
  TextField,
  Backdrop,
  CircularProgress,
  Stack,
} from "@mui/material";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";
import FormMediaRecordList from "./FormMediaRecordList";
import FormAlertError from "./FormAlertError";
import { DownloaderFormInput, MediaRecordInput } from "./FormInterfaces";
import { styles } from "./styles";
import { useGetProduct } from "../../hooks/useGetProduct";
import { useGetMediaRecord } from "../../hooks/useGetMediaRecord";
import { ProductType } from "../../services/ProductService";
import {
  MP3_CODEC,
  BITRATE_64_KBPS,
  FORMAT_64K_MP3,
} from "../../utils/AudioFormatConstants";

const VALID_PRODUCT_CODE_LENGTH = 4;

const mediaRecordInputEmpty: MediaRecordInput[] = [
  {
    bibleId: "",
    language: "",
    mediaId: "",
    format: "",
    version: "",
    description: "",
  },
];

const getValidMediaRecords = (product: ProductType): MediaRecordInput[] => {
  const newMediaRecords: MediaRecordInput[] = [];

  product?.media_records.forEach((mediaRecord) => {
    if (
      mediaRecord.codec === MP3_CODEC &&
      mediaRecord.bitrate === BITRATE_64_KBPS
    ) {
      newMediaRecords.push({
        language: mediaRecord.language,
        mediaId: mediaRecord.media_id,
        format: FORMAT_64K_MP3,
        version: mediaRecord.version,
        bibleId: mediaRecord.bible_id,
        description: mediaRecord.description,
      });
    }
  });

  return newMediaRecords;
};

const FormDownloader = () => {
  const {
    control,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
    clearErrors,
  } = useForm<DownloaderFormInput>({
    defaultValues: {
      productCode: "",
      mediarecords: mediaRecordInputEmpty,
    },
  });

  const { fields } = useFieldArray({
    control,
    name: "mediarecords",
  });

  const [productCode, setProductCode] = useState<string>(
    getValues("productCode")
  );

  const updateMediaRecords = useCallback(
    (inputRecords: MediaRecordInput[]): void => {
      setValue("mediarecords", inputRecords);
    },
    [setValue]
  );

  const {
    isFetching,
    isError,
    data: productLoaded,
    refetch: fetchProduct,
  } = useGetProduct({ productCode });

  const {
    isFetching: isFetchingPackage,
    isError: isErrorPackage,
    setMediaRecordToDownload,
  } = useGetMediaRecord();

  const onSubmit = (index: number): (() => void) => {
    return () => {
      const { mediaId, bibleId, description } = getValues(
        `mediarecords.${index}`
      );

      setMediaRecordToDownload({
        mediaId,
        bibleId,
        format: FORMAT_64K_MP3,
        id3v2: "stripped",
        productCode: description,
      });
    };
  };

  const productCodeChangeDebounced = useDebouncedCallback((value): void => {
    setProductCode(value);

    if (!isValid) {
      clearErrors("productCode");
    }
  }, 500);

  const handleProductCodeChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): string => {
    setValue("productCode", event.target.value);

    if (
      event.target.value.trim() &&
      event.target?.value.length >= VALID_PRODUCT_CODE_LENGTH
    ) {
      productCodeChangeDebounced(getValues("productCode"));
    } else if (event.target.value === "") {
      updateMediaRecords(mediaRecordInputEmpty);

      if (!isValid) {
        clearErrors("productCode");
      }
    }
    return event.target.value;
  };

  useEffect(() => {
    if (productCode) {
      fetchProduct();
    }
  }, [productCode, fetchProduct]);

  useEffect(() => {
    if (productLoaded) {
      const newMediaRecords = getValidMediaRecords(productLoaded);

      if (newMediaRecords.length) {
        updateMediaRecords(newMediaRecords);
      } else {
        updateMediaRecords(mediaRecordInputEmpty);
      }
    }
  }, [productLoaded, updateMediaRecords]);

  useEffect(() => {
    if (isError) {
      updateMediaRecords(mediaRecordInputEmpty);
    }
  }, [isError, updateMediaRecords]);

  return (
    <Box>
      <Stack spacing={1}>
        <FormAlertError
          isError={isError}
          message={"There was an error trying to load Product — try again!"}
        />
        <FormAlertError
          isError={isErrorPackage}
          message={"There was an error trying to download Package — try again!"}
        />
      </Stack>

      <Box sx={styles.container}>
        <Backdrop sx={styles.backdrop} open={isFetching || isFetchingPackage}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box component="form">
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2}>
              <Controller
                name={"productCode"}
                control={control}
                rules={{
                  required: true,
                  minLength: {
                    value: VALID_PRODUCT_CODE_LENGTH,
                    message: `Min length is ${VALID_PRODUCT_CODE_LENGTH}`,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Product Code"
                    onChange={handleProductCodeChange}
                    onKeyPress={(event: KeyboardEvent<HTMLDivElement>) => {
                      if (event.key === "Enter") {
                        trigger("productCode");
                      }
                    }}
                    error={errors["productCode"]?.type !== undefined}
                    helperText={
                      errors["productCode"]?.type !== undefined
                        ? errors["productCode"]?.message
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={10}>
              <FormMediaRecordList
                fields={fields}
                control={control}
                errors={errors}
                onSubmit={onSubmit}
                getValues={getValues}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default FormDownloader;
