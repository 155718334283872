import {
  Grid,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  Divider,
} from "@mui/material";
import { Controller } from "react-hook-form";
import FormField from "./FormField";
import { FormMediaRecordListProps } from "./FormInterfaces";
import {
  FORMAT_64K_MP3,
  FORMAT_64K_WMA,
} from "../../utils/AudioFormatConstants";

const FormMediaRecordList = ({
  fields,
  control,
  errors,
  onSubmit,
  getValues,
}: FormMediaRecordListProps) => {
  return (
    <>
      {fields.map((item: Record<"id", string>, index: number) => (
        <Grid key={item.id} container direction="row" spacing={1}>
          <Grid item xs={2}>
            <Controller
              name={`mediarecords.${index}.format`}
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value={FORMAT_64K_MP3}
                    control={<Radio />}
                    label="64k mp3"
                  />
                  <Tooltip title="wma format is not yet supported">
                    <FormControlLabel
                      value={FORMAT_64K_WMA}
                      control={<Radio disabled />}
                      label="64k wma"
                    />
                  </Tooltip>
                </RadioGroup>
              )}
            />
            <Controller
              render={({ field }) => <input {...field} hidden />}
              name={`mediarecords.${index}.bibleId`}
              control={control}
            />
            <Controller
              render={({ field }) => <input {...field} hidden />}
              name={`mediarecords.${index}.description`}
              control={control}
            />
          </Grid>
          <Grid item xs={2}>
            <FormField
              control={control}
              error={
                errors[`mediarecords.${index}.language`]?.type === "required"
              }
              label="Language"
              name={`mediarecords.${index}.language`}
              defaultValue=""
              required={true}
              readOnly={true}
            />
          </Grid>
          <Grid item xs={3}>
            <FormField
              control={control}
              error={
                errors[`mediarecords.${index}.version`]?.type === "required"
              }
              label="Version"
              name={`mediarecords.${index}.version`}
              defaultValue=""
              required={true}
              readOnly={true}
            />
          </Grid>
          <Grid item xs={3}>
            <FormField
              control={control}
              error={
                errors[`mediarecords.${index}.mediaId`]?.type === "required"
              }
              label="Media ID"
              name={`mediarecords.${index}.mediaId`}
              defaultValue=""
              required={true}
              readOnly={true}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={onSubmit(index)}
              fullWidth
              disabled={!getValues(`mediarecords.${index}.mediaId`)}
            >
              Download
            </Button>
          </Grid>
          {Number(index) + 1 !== fields.length ? (
            <Grid item xs={12} p={2}>
              <Divider variant="fullWidth" component="div" />
            </Grid>
          ) : null}
        </Grid>
      ))}
    </>
  );
};

export default FormMediaRecordList;
