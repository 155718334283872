import { TextField } from "@mui/material";
import { Controller, FieldValues } from "react-hook-form";

interface FormFieldProps {
  control: FieldValues | any;
  error: boolean;
  label: string;
  name: string;
  defaultValue: string;
  required: boolean;
  readOnly: boolean;
}

const FormField = ({
  control,
  error,
  label,
  name,
  defaultValue,
  required,
  readOnly,
}: FormFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required,
      }}
      render={({ field }) => (
        <TextField
          {...field}
          fullWidth
          label={label}
          error={error}
          InputProps={{
            readOnly,
          }}
        />
      )}
    />
  );
};

export default FormField;
