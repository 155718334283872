import { Grid, Container, Typography, Box } from "@mui/material";
import FormDownloader from "../FormDownloader/FormDownloader";
import { styles } from "./styles";

const Home = () => {
  return (
    <Box
      sx={(theme) => ({ backgroundColor: theme.palette.background.default })}
    >
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1" sx={styles.homeTitle}>
              Bible Brain Downloader
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormDownloader />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Home;
